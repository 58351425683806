
import { defineComponent, ref, inject } from 'vue';
import { Input } from '@/components/input-elements';

export default defineComponent({
    components: {
        Input
        // Select
    },
    setup(props, context) {
        const emitter: any = inject('emitter');
        const infoFromRef = ref<null | HTMLFormElement>(null);
        const formData = ref({
            id: null,
            name: '',
            description: '',
            code: '',
            status: false,
            variable_discount: false
        });
        const columns = ref([
            {
                label: 'message.NAME',
                key: 'site_name'
            },
            {
                label: 'message.DISCOUNT_TYPE',
                key: 'price'
            },
            {
                label: 'message.VALUE',
                key: 'tax'
            }
        ]);
        const codeGenerate = length => {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ012345678910abcdefghijklmnopqrstuvwxyz';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }

            formData.value.code = result;
        };

        const resetForm = () => {
            emitter.emit('clearInput');
            formData.value.id = null;
            infoFromRef.value?.resetFields();
        };

        const editDiscountValue = (data: any) => {
            resetForm();
            if(data) {
                formData.value.id = data.id;
                formData.value.name = data?.name;
                formData.value.description = data?.description;
                formData.value.status = data?.status == 1 ? true : false;
                formData.value.code = data?.code;
            }
        };

        const validateForm = () => {
            infoFromRef.value?.validate(valid => {
                if (valid) {
                    context.emit('store', 'generalForm', formData.value, valid);
                    return valid;
                } else {
                    context.emit('store', 'generalForm', formData.value, valid);
                    return valid;
                }
            });
        };

        return {
            formData,
            infoFromRef,
            columns,
            codeGenerate,
            validateForm,
            resetForm,
            editDiscountValue
        };
    }
});
