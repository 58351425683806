import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "demo-date-picker mb-7" }
const _hoisted_2 = { class: "block" }
const _hoisted_3 = { class: "d-flex align-items-center fs-6 fw-bold mb-1" }
const _hoisted_4 = {
  key: 3,
  class: "row"
}
const _hoisted_5 = { class: "col-md-12" }
const _hoisted_6 = { class: "col-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InnerLoader = _resolveComponent("InnerLoader")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Input = _resolveComponent("Input")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_InnerLoader, { loading: _ctx.loading }, null, 8, ["loading"]),
    _createVNode(_component_el_form, {
      model: _ctx.conditionForm,
      ref: "conditionFromRef"
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          _createVNode("div", _hoisted_2, [
            _createVNode("label", _hoisted_3, [
              _createVNode("span", null, _toDisplayString(_ctx.$t('message.SELECT_DATE')), 1)
            ]),
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.conditionForm.date,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.conditionForm.date = $event)),
              type: "daterange",
              "range-separator": _ctx.$t('message.TO'),
              "start-placeholder": _ctx.$t('message.START_DATE'),
              "end-placeholder": _ctx.$t('message.END_DATE'),
              "disabled-date": _ctx.disabledDate,
              format: "DD-MM-YYYY",
              "value-format": "DD-MM-YYYY"
            }, null, 8, ["modelValue", "range-separator", "start-placeholder", "end-placeholder", "disabled-date"])
          ])
        ]),
        _createVNode(_component_Select, {
          name: "available_for",
          target: "available_for",
          label: _ctx.$t('message.AVAILABLE_FOR'),
          clearable: true,
          callEmit: true,
          required: true,
          available_for: _ctx.conditionForm.available_for,
          "onUpdate:available_for": _cache[2] || (_cache[2] = ($event: any) => (_ctx.conditionForm.available_for = $event)),
          selectData: _ctx.available_options,
          value: _ctx.conditionForm.available_for,
          onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeHandler(_ctx.conditionForm.available_for)))
        }, null, 8, ["label", "available_for", "selectData", "value"]),
        (_ctx.conditionForm.available_for == 'SINGLE_CUSTOMER' || _ctx.conditionForm.available_for == 'CUSTOMER_GROUP' || 
            _ctx.conditionForm.available_for == 'SITE' || _ctx.conditionForm.available_for  == 'COUNTRY')
          ? (_openBlock(), _createBlock(_component_Select, {
              key: 0,
              name: "for_data",
              target: "for_data",
              label: _ctx.label,
              multiple: true,
              clearable: true,
              required: true,
              filterable: true,
              for_data: _ctx.conditionForm.for_data,
              "onUpdate:for_data": _cache[4] || (_cache[4] = ($event: any) => (_ctx.conditionForm.for_data = $event)),
              selectData: _ctx.available_options_for,
              value: _ctx.conditionForm.for_data
            }, null, 8, ["label", "for_data", "selectData", "value"]))
          : _createCommentVNode("", true),
        (_ctx.discount_type == 'SUBSCRIPTION_DISCOUNT')
          ? (_openBlock(), _createBlock(_component_Select, {
              key: 1,
              name: "frequency",
              target: "frequency",
              label: _ctx.$t('message.FREQUENCY'),
              clearable: true,
              callEmit: true,
              required: true,
              frequency: _ctx.conditionForm.frequency,
              "onUpdate:frequency": _cache[5] || (_cache[5] = ($event: any) => (_ctx.conditionForm.frequency = $event)),
              selectData: _ctx.frequency_options,
              value: _ctx.conditionForm.frequency
            }, null, 8, ["label", "frequency", "selectData", "value"]))
          : _createCommentVNode("", true),
        (_ctx.discount_type == 'SUBSCRIPTION_DISCOUNT')
          ? (_openBlock(), _createBlock(_component_Input, {
              key: 2,
              type: "number",
              name: "duration",
              label: _ctx.$t('message.DURATION'),
              duration: _ctx.conditionForm.duration,
              "onUpdate:duration": _cache[6] || (_cache[6] = ($event: any) => (_ctx.conditionForm.duration = $event)),
              target: "duration",
              required: true,
              value: _ctx.conditionForm.duration
            }, null, 8, ["label", "duration", "value"]))
          : _createCommentVNode("", true),
        (_ctx.discount_type == 'ORDER_DISCOUNT')
          ? (_openBlock(), _createBlock("div", _hoisted_4, [
              _createVNode("div", _hoisted_5, [
                _createVNode(_component_Input, {
                  type: "number",
                  name: "total_available",
                  label: _ctx.$t('message.TOTAL_AVAILABLE_COUPON'),
                  total_available: _ctx.conditionForm.total_available,
                  "onUpdate:total_available": _cache[7] || (_cache[7] = ($event: any) => (_ctx.conditionForm.total_available = $event)),
                  target: "total_available",
                  required: true,
                  value: _ctx.conditionForm.total_available
                }, null, 8, ["label", "total_available", "value"])
              ]),
              _createVNode("div", _hoisted_6, [
                _createVNode(_component_Input, {
                  type: "number",
                  name: "available_per_use",
                  label: _ctx.$t('message.AVAILABLE_PER_USE'),
                  available_per_use: _ctx.conditionForm.available_per_use,
                  "onUpdate:available_per_use": _cache[8] || (_cache[8] = ($event: any) => (_ctx.conditionForm.available_per_use = $event)),
                  target: "available_per_use",
                  required: true,
                  value: _ctx.conditionForm.available_per_use
                }, null, 8, ["label", "available_per_use", "value"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["model"])
  ], 64))
}