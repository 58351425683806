
import { defineComponent, ref, inject } from 'vue';
import { Input, Select } from '@/components/input-elements';
import { Apollo } from '@/core/services';
import { GET_PRODUCT_FOR_DISCOUNT_LIST } from '../../graphql/Queries';
import { useStore } from 'vuex';
import InnerLoader from '@/components/InnerLoader.vue';

export default defineComponent({
    components: {
        Input,
        Select,
        InnerLoader
    },
    props: {
        discount_type: String
    },
    setup(props, context) {
        const products = ref([]) as Record<any, any>;
        const store = useStore();
        const loading = ref(false);
        const locale = ref('en');
        const actionFromRef = ref<null | HTMLFormElement>(null);
        const emitter: any = inject('emitter');

        const LoadProducts = () => {
            loading.value = true;
            Apollo.watchQuery({
                query: GET_PRODUCT_FOR_DISCOUNT_LIST,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                errorPolicy: 'all'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loading.value = false;
                }
                products.value = [];
                data.products.data.forEach(element => {
                    products.value.push({
                        label: JSON.parse(element.description.name)[`${locale.value}`].name,
                        value: element.id
                    });
                });
                loading.value = false;
            });
        };

        const actionForm = ref([
            {
                discount_type: '',
                discount_amount: '',
                product_id: '',
                shipping_status: false
            }
        ]);

        const addProductDiscount = () => {
            actionForm.value.push({
                discount_type: '',
                discount_amount: '',
                product_id: '',
                shipping_status: false
            });
        };

        const removeProductDiscount = index => {
            actionForm.value.splice(index, 1);
        };

        const discount_options = ref([
            {
                label: 'message.AMOUNT',
                value: 'AMOUNT'
            },
            {
                label: 'message.PERCENTAGE',
                value: 'PERCENT'
            }
        ]);

        const changeDiscountTypeHandler = async data => {
            if (data == 'PRODUCT') {
                await LoadProducts();
            }
        };

        const resetForm = () => {
            emitter.emit('clearInput');
            actionForm.value = [
                {
                    discount_type: '',
                    discount_amount: '',
                    product_id: '',
                    shipping_status: false
                }
            ];
            actionFromRef.value?.resetFields();
            actionFromRef.value?.model.forEach(form => {
                form.discount_amount != '' ? (form.discount_amount = '') : null;
                form.discount_type != '' ? (form.discount_type = '') : null;
                form.product_id != '' ? (form.product_id = '') : null;
            });
        };

        const editDiscountValue = async (data: any) => {
            resetForm();
            if (products.value.length == 0) {
                await LoadProducts();
            }
            if (data && data.subscription_discount_action) {
                actionForm.value = [];
                data.subscription_discount_action.forEach(discount => {
                    actionForm.value.push({
                        discount_type: discount.discount_type,
                        discount_amount: discount.discount_value,
                        product_id: discount.product_id,
                        shipping_status: false
                    });
                });
            }
            if (data && data.discount_action) {
                actionForm.value = [
                    {
                        discount_type: data.discount_action.discount_type,
                        discount_amount: data.discount_action.discount_value,
                        product_id: data.discount_action.product_id,
                        shipping_status: false
                    }
                ];
            }
        };

        const validateForm = () => {
            let valid = true;
            actionFromRef.value?.model.forEach(form => {
                form.discount_type == '' ? (valid = false) : null,
                    (form.discount_type == 'AMOUNT' || form.discount_type == 'PERCENT') && form.discount_amount == '' ? (valid = false) : null,
                    (form.discount_type == 'PRODUCT' || props.discount_type == 'SUBSCRIPTION_DISCOUNT') && form.product_id == '' ? (valid = false) : null;
            });

            if (valid) {
                context.emit('store', 'actionForm', actionForm.value, true);
                return valid;
            } else {
                context.emit('store', 'actionForm', actionForm.value, false);
                return valid;
            }
        };

        return {
            products,
            discount_options,
            actionForm,
            changeDiscountTypeHandler,
            validateForm,
            actionFromRef,
            resetForm,
            editDiscountValue,
            loading,
            addProductDiscount,
            removeProductDiscount
        };
    }
});
