import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DiscountListing = _resolveComponent("DiscountListing")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_AddDiscount = _resolveComponent("AddDiscount")!
  const _component_EditDiscount = _resolveComponent("EditDiscount")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_tabs, {
      class: "discount-tabs",
      "tab-position": 'top',
      modelValue: _ctx.tabsValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.tabsValue = $event)),
      onClick: _ctx.tabClick
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: _ctx.$t('message.ORDER_DISCOUNTS')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DiscountListing, { discount_type: 'ORDER_DISCOUNT' })
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_tab_pane, {
          label: _ctx.$t('message.SUBSCRIPTION_DISCOUNTS')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DiscountListing, { discount_type: 'SUBSCRIPTION_DISCOUNT' })
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    }, 8, ["modelValue", "onClick"]),
    _createVNode(_component_AddDiscount, { discount_type: _ctx.discount_type }, null, 8, ["discount_type"]),
    _createVNode(_component_EditDiscount, { discount_type: _ctx.discount_type }, null, 8, ["discount_type"])
  ], 64))
}