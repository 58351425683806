import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row align-items-center"
}
const _hoisted_2 = { class: "col-md-9" }
const _hoisted_3 = {
  class: "col-md-3",
  style: {"text-align":"end"}
}
const _hoisted_4 = { class: "fv-row mb-5" }
const _hoisted_5 = { class: "d-flex flex-stack" }
const _hoisted_6 = { class: "me-5 mt-3" }
const _hoisted_7 = { class: "fs-5 fw-bold" }
const _hoisted_8 = { class: "fs-7 fw-bold text-gray-400" }
const _hoisted_9 = { class: "form-check-label fw-bold text-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    model: _ctx.formData,
    ref: "infoFromRef"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Input, {
        type: "text",
        name: "name",
        label: `${_ctx.$t('message.ENTER')} ${_ctx.$t('message.NAME')}`,
        "onUpdate:name": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.name = $event)),
        target: "name",
        required: true,
        value: _ctx.formData.name
      }, null, 8, ["label", "name", "value"]),
      _createVNode(_component_Input, {
        type: "textarea",
        name: "description",
        label: `${_ctx.$t('message.ENTER')} ${_ctx.$t('message.DESCRIPTION')}`,
        description: _ctx.formData.description,
        "onUpdate:description": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formData.description = $event)),
        target: "description",
        required: true,
        value: _ctx.formData.description
      }, null, 8, ["label", "description", "value"]),
      (_ctx.formData.id == null)
        ? (_openBlock(), _createBlock("div", _hoisted_1, [
            _createVNode("div", _hoisted_2, [
              _createVNode(_component_Input, {
                type: "text",
                name: "code",
                label: `${_ctx.$t('message.ENTER')} ${_ctx.$t('message.CODE')}`,
                code: _ctx.formData.code,
                "onUpdate:code": _cache[3] || (_cache[3] = ($event: any) => (_ctx.formData.code = $event)),
                target: "code",
                required: true,
                disabled: true,
                value: _ctx.formData.code
              }, null, 8, ["label", "code", "value"])
            ]),
            _createVNode("div", _hoisted_3, [
              _createVNode(_component_el_button, {
                type: "info",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.codeGenerate(6))),
                class: "mt-4 btn-custom",
                plain: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('message.GENERATE')), 1)
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode("div", _hoisted_4, [
        _createVNode("div", _hoisted_5, [
          _createVNode("div", _hoisted_6, [
            _createVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('message.DISCOUNT_STATUS')), 1),
            _createVNode("div", _hoisted_8, _toDisplayString(`${_ctx.$t('message.DISCOUNT')} ${_ctx.$t('message.ACTIVATE_AND_DEACTIVATE')}`), 1)
          ]),
          _createVNode("span", _hoisted_9, [
            _createVNode(_component_el_switch, {
              modelValue: _ctx.formData.status,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.formData.status = $event))
            }, null, 8, ["modelValue"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["model"]))
}