
import DiscountListing from "@/modules/system/discounts/components/DiscountListing.vue"
import AddDiscount from "@/modules/system/discounts/components/AddDiscount.vue"
import EditDiscount from "@/modules/system/discounts/components/EditDiscount.vue"
import {defineComponent, onMounted, ref} from 'vue';
import {setPageHeader} from "@/core/helpers/toolbar";

export default defineComponent({
    components: {
      DiscountListing,
      AddDiscount,
      EditDiscount
    },
    setup() {
        const discount_type: any = ref('ORDER_DISCOUNT');
        const tabsValue: any = ref();

        onMounted(() => {
            setPageHeader({
                title: "message.DISCOUNTS",
                actionButton: {
                    ability: 'add_discounts',
                    pageAction: {
                        action: 'addDiscountAction',
                    },
                    button: {
                        icon: "bi bi-plus",
                        openModal: true,
                    }
                },
                breadCrumbs: [
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.SYSTEM", link: "#"},
                    { name: "message.SETTINGS", link: "/system/settings"},
                    { name: "message.DISCOUNTS"}
                ]
            });
        })
        
        const tabClick = () => {
            if(tabsValue.value == 0) {
                discount_type.value = 'ORDER_DISCOUNT'
            } else if(tabsValue.value == 1) {
                discount_type.value = 'SUBSCRIPTION_DISCOUNT'
            }
        }

        return {
            discount_type,
            tabsValue,
            tabClick
        }
    }
});
