
import { defineComponent, ref, inject, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { Apollo, Notify } from '@/core/services';
import { DELETE_DISCOUNT } from '../graphql/Mutations';
import { GET_DISCOUNTS, SEARCH_DISCOUNTS } from '../graphql/Queries';
import { useI18n } from 'vue-i18n';
import Search from '../../../../components/search/Search.vue';
import Table from '../../../../components/Table/Table.vue';
import InnerLoader from '../../../../components/InnerLoader.vue';
import { dropdown_handler } from '../../../../core/helpers/dropdownHandler';

export default defineComponent({
    name: 'Discounts',
    components: { Search, Table, InnerLoader },
    props: {
        discount_type: String
    },
    setup(props) {
        const store = useStore();
        const discounts: any = ref([]);
        const loader = ref(false);
        const loading = ref(false);
        const pagination = ref({}) as Record<any, any>;
        const emitter: any = inject('emitter');
        const i18n = useI18n();
        const clickHandler = ref() as Record<any, any>;
        const currentPage = ref();
        const permissions = store.getters?.getPermissions;
        const activeIndex = ref(-1);
        const searchObservable: any = ref(null);
        const observable: any = ref(null);
        const columns = ref([
            {
                label: 'message.NAME',
                key: 'name'
            },
            {
                label: 'message.CODE',
                key: 'code'
            },
            {
                label: 'message.TIME_FROM',
                key: 'time_from'
            },
            {
                label: 'message.TIME_TO',
                key: 'time_to'
            },
            {
                label: 'message.STATUS',
                key: 'status'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        const filterOBject = ref([
            {
                label: 'message.NAME',
                value: 'name'
            },
            {
                label: 'message.CODE',
                value: 'code'
            }
        ]);

        const handleCurrentChange = (num: number) => {
            clickHandler.value(num);
        };

        const dropdownHandler = (index, id) => {
            activeIndex.value = index === id ? null : id;
        };
        const handleClickOutside = event => {
            if (!event.target.closest('.action-btn')) {
                activeIndex.value = -1;
            }
        };

        const getDiscounts = (page = 1, reload = false) => {
            if (searchObservable.value != null) {
                searchObservable.value?.unsubscribe();
                searchObservable.value = null;
            }
            if (page == 1) observable.value?.unsubscribe();
            loader.value = true;
            observable.value = Apollo.watchQuery({
                query: GET_DISCOUNTS,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-and-network',
                errorPolicy: 'all',
                variables: {
                    discount_type: props.discount_type,
                    page: page,
                    limit: 10
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                reload ? (currentPage.value = 1) : false;
                clickHandler.value = getDiscounts;
                discounts.value = [];
                discounts.value = data?.discounts?.data;
                pagination.value = data?.discounts;
                loader.value = false;
            });
        };

        const handleEdit = (discount: object) => {
            emitter.emit('editDiscountAction', discount);
        };

        const searchHandler = (page = 1) => {
            if (observable.value != null) {
                observable.value?.unsubscribe();
                observable.value = null;
            }
            searchObservable.value?.unsubscribe();
            const searchData = store.getters.getSearchData;
            loader.value = true;
            searchObservable.value = Apollo.watchQuery({
                query: SEARCH_DISCOUNTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                variables: {
                    discount_type: props.discount_type,
                    page: page,
                    limit: 10,
                    filter: searchData.data.select,
                    search_key: searchData.data.input
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                clickHandler.value = searchHandler;
                discounts.value = [];
                discounts.value = data.search_discounts.data;
                pagination.value = data.search_discounts;
                loader.value = false;
            });
        };

        const handleDelete = (id: number) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: DELETE_DISCOUNT,
                        variables: { id: id },
                        update: (store, { data: { delete_discount } }) => {
                            if (searchObservable.value != null) searchHandler(pagination.value.current_page);
                            else getDiscounts(pagination.value.current_page);
                            loader.value = false;
                        }
                    });
                    Notify.success(i18n.t('message.RECORD_DELETED_SUCCESSFULLY'));
                })
                .catch(() => {
                    loader.value = false;
                });
        };

        const exportFile = data => {
            const searchData = store.getters.getSearchData;
            loading.value = true;
            const sub = Apollo.watchQuery({
                query: SEARCH_DISCOUNTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                variables: {
                    page: 0,
                    limit: 1,
                    filter: searchData?.data?.select ? searchData?.data?.select : '',
                    search_key: searchData?.data?.input ? searchData?.data?.input : '',
                    type: data?.type,
                    date_from: data?.dateFrom,
                    date_to: data?.dateTo
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loading.value = false;
                } else if (data) {
                    window.open(data?.search_discounts?.data[0]?.path, '_blank');
                    loading.value = false;
                }

                loading.value = false;
                sub.unsubscribe();
            });
        };

        onMounted(() => {
            getDiscounts();
            document.addEventListener('click', handleClickOutside);
        });

        onUnmounted(() => {
            observable.value?.unsubscribe();
            searchObservable.value?.unsubscribe();
        });

        return {
            discounts,
            pagination,
            filterOBject,
            currentPage,
            columns,
            permissions,
            loading,
            loader,
            exportFile,
            handleCurrentChange,
            getDiscounts,
            searchHandler,
            handleEdit,
            handleDelete,
            activeIndex,
            dropdownHandler,
            handleClickOutside
        };
    }
});
